<template>
  <div class="bg-white shadow-sm rounded-lg p-4">
    <div class="row mb-2 justify-content-between">
      <div v-if="discriminator === 'administrator'" class="col-4 ml-0 pr-2">
        <multiselect class="multiselect-top" v-model="officeFilter" @input="filterData" :options="offices"
          :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
          placeholder="Select Offices" label="office_name" track-by="id" :select-label="''" :deselect-label="''"
          @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen">{{
                values
                  .map((item) => {
                    return item.office_name;
                  })
                  .join(", ")
              }}</span></template>
        </multiselect>
      </div>
      <div class="col-4 ml-0 pr-2">
        <multiselect class="multiselect-top" v-model="clientFilter" @input="filterData" :options="clients"
          :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
          placeholder="Select Client" label="name" track-by="uuid" :select-label="''" :deselect-label="''"
          @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen">{{
                values
                  .map((item) => {
                    return item.name;
                  })
                  .join(", ")
              }}</span></template>
        </multiselect>
      </div>
      <div class="col-4 ml-0 pr-2">
        <multiselect class="multiselect-top" v-model="clientTypeFilter" @input="filterData" :options="clientTypes"
          :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
          placeholder="Select Client Type" label="name" track-by="name" :select-label="''" :deselect-label="''"
          @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen">{{
                values
                  .map((item) => {
                    return item.name;
                  })
                  .join(", ")
              }}</span></template>
        </multiselect>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-3 ml-0 pr-2">
        <multiselect class="multiselect-bottom" v-model="statusFilter" @input="filterData" :options="status"
          :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
          placeholder="Select Status" label="status" track-by="status" :select-label="''" :deselect-label="''"
          @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen">{{
                values
                  .map((item) => {
                    return item.status;
                  })
                  .join(", ")
              }}</span></template>
        </multiselect>
      </div>
      <div class="col-3 ml-0 pr-2">
        <multiselect class="multiselect-bottom" v-model="coverageFilter" @input="filterData" :options="coverages"
          :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
          placeholder="Select Coverage" label="name" track-by="id" :select-label="''" :deselect-label="''"
          @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen">{{
                values
                  .map((item) => {
                    return item.name;
                  })
                  .join(", ")
              }}</span></template>
        </multiselect>
      </div>
      <div class="col-3 ml-0 pr-2">
        <multiselect class="multiselect-bottom" v-model="carrierFilter" @input="filterData" :options="carriers"
          :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
          placeholder="Select Carrier" label="name" track-by="id" :select-label="''" :deselect-label="''"
          @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen">{{
                values
                  .map((item) => {
                    return item.name;
                  })
                  .join(", ")
              }}</span></template>
        </multiselect>
      </div>
      <div class="col-3 ml-0 pr-2">
        <date-picker type="date" v-model="dateFilter" @change="filterData" range format="MM/DD/YYYY"
          placeholder="Select Range Date"></date-picker>
      </div>
    </div>
    <el-table :data="tableData" :default-sort="{ prop: 'corporate.name', order: 'ascending' }" class="w-100" :fit="true">
      <el-table-column label="Client Name" prop="corporate.name" sortable :show-overflow-tooltip="true"
        width="150"></el-table-column>
      <el-table-column label="Type" sortable prop="insurance_type" :show-overflow-tooltip="true" width="60">
        <template slot-scope="scope">
          <span class="text-capitalize">
            {{
              scope.row.insurance_type
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Coverage" sortable prop="coverage.name" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="Wholesaler" sortable prop="wholesaler.name" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="Bill Type" sortable prop="bill_type" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span class="text-capitalize">
            {{
              scope.row.bill_type
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Carrier" sortable prop="carrier.name" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="Policy #" sortable prop="policy" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="Finance Company" sortable prop="finance_company.name"
        :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="Effective Date" sortable prop="effective_date" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{
          scope.row.effective_date | moment("MM-DD-YYYY")
        }}</template>
      </el-table-column>
      <el-table-column label="Expiration Date" sortable prop="expiration_date" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{
          scope.row.expiration_date | moment("MM-DD-YYYY")
        }}</template>
      </el-table-column>
      <el-table-column label="Total Premium" sortable prop="total_premium" :show-overflow-tooltip="true"><template
          slot-scope="scope">$
          {{
            new Intl.NumberFormat().format(scope.row.total_premium)
          }}</template></el-table-column>
      <el-table-column label="Status" sortable prop="status" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{
          scope.row.status ? 'Active' : 'Inactive'
        }}</template>
      </el-table-column>
      <el-table-column label="" width="80">
        <template slot-scope="scope">
          <center>
            <el-button-group>
              <el-button size="mini" icon="el-icon-edit" @click="updatePolicy(scope.row, scope.$index)"></el-button>
            </el-button-group>
          </center>
        </template>
      </el-table-column>
    </el-table>

    <el-button class="fixed-bottom new-register" type="primary" @click="add">Add New Policy</el-button>

    <el-button class="fixed-bottom new-register" style="margin-left: 130px" type="primary" @click="print"><i
        class="fal fa-print"></i> Print Report</el-button>
    <download-excel class="el-button fixed-bottom new-register el-button--primary fixed-bottom new-register"
      style="margin-left:480px;" :fetch="exportExcel" worksheet="Insurances" name="insurance.xls">
      Export Excel
    </download-excel>
    <router-link to="/admin/installments">
      <el-button class="fixed-bottom new-register" style="margin-left: 280px" type="primary">Insurance
        Installments</el-button>
    </router-link>

    <el-drawer title="Add New Policy" :visible.sync="component.drawer" direction="rtl" size="1800px">
      <div class="pt-3 pl-5 pr-5 pb-5">
        <component-policy v-on:refresh="load($event)" :data="component.data" :key="component.render" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import policy from "@/services/api/policy";
import corporate from "@/services/api/corporate";
import office from "@/services/api/office";
import officeUser from "@/services/api/officeUser";
import Multiselect from "vue-multiselect";
import ComponentPolicy from "@/components/admin/Policy";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import coverage from "@/services/api/coverage";
import carrier from "@/services/api/carrier";
import downloadExcel from "vue-json-excel";

export default {
  components: {
    Multiselect,
    DatePicker,
    ComponentPolicy,
    downloadExcel
  },
  data() {
    return {
      data: [],
      index: null,
      tableData: [],
      officeFilter: [],
      clientFilter: [],
      clientTypeFilter: [],
      statusFilter: [],
      coverageFilter: [],
      carrierFilter: [],
      dateFilter: [null, null],
      discriminator: "",
      search: "",
      offices: [],
      clients: [],
      clientTypes: [{ name: "P" }, { name: "C" }],
      status: [{ status: "Active" }, { status: "Expired" }, { status: "Cancelled" }],
      coverages: [],
      carriers: [],
      component: {
        data: null,
        drawer: false,
        render: 0,
      },
    };
  },
  mounted() {
    coverage.get().then((response) => {
      this.coverages = response;
    });
    carrier.get().then((response) => {
      this.carriers = response;
    });
    switch (this.$store.getters.discriminator) {
      case "administrator":
        this.discriminator = "administrator";
        office.get().then((response) => {
          this.offices = response;
        });
        break;
      case "office":
      case "employee": {
        this.discriminator = "office";
        let idOffice = null;
        officeUser.getOffice(this.$store.getters.id).then((response) => {
          this.offices = response.map((item) => {
            idOffice = item.office.id;
            return {
              id: item.office.id,
              office_name: item.office.office_name,
            };
          });
        });
        break;
      }
    }
    policy.get().then((response) => {
      this.data = Object.assign([], response);
      this.tableData = response;
      console.log(response);
    });
  },
  methods: {
    exportExcel() {
      return this.tableData.map((item, i) => ({
        "Client Name": item.corporate.name,
        "Type": item.insurance_type,
        "Coverage": item.coverage.name,
        "Wholesaler": item.wholesaler.name,
        "Bill Type": item.bill_type,
        "Carrier": item.carrier.name,
        "Policy #": item.policy,
        "Finance Company": item.finance_company.name,
        "Effective Date": item.effective_date,
        "Expiration Date": item.expiration_date,
        "Total Premium": new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.total_premium),
        "Status": item.status ? 'Active' : 'Inactive'
      }))
    },
    add() {
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = null;
    },
    updatePolicy(row, index) {
      this.index = index;
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = row;
    },
    numberFormat(number) {
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;
      const rep = '$1,';
      let arr = number.toString().split('.');
      arr[0] = arr[0].replace(exp, rep);
      return arr[1] ? arr.join('.') : arr[0];
    },
    load(event) {
      this.component.drawer = false;
      if (event.update) {
        this.tableData.splice(this.index, 1, event.data);
      } else {
        this.tableData.unshift(event.data);
      }
    },
    getClients(idOffices) {
      this.clients = [];
      idOffices.forEach((id) => {
        corporate.getClientsByOffice(id).then((response) => {
          this.clients = this.clients.concat(response);
        });
      });
    },
    filterData() {
      let idOffices = this.officeFilter.map((office) => {
        return office.id;
      });

      let clients = this.clientFilter.map((client) => {
        return client.id;
      });

      let clientTypes = this.clientTypeFilter.map((user) => {
        return user.name.toLowerCase();
      });

      let status = this.statusFilter.map((status) => {
        return status.status;
      });

      let coverages = this.coverageFilter.map((coverage) => {
        return coverage.id;
      });

      let carriers = this.carrierFilter.map((carrier) => {
        return carrier.id;
      });

      this.tableData = Object.assign([], this.data);

      if (idOffices.length > 0) {
        this.tableData = this.tableData.filter(
          (item) =>
            idOffices.indexOf(item.officeId) != -1
        );
      }

      if (idOffices.length !== this.lengthOfficeFilter) {
        this.lengthOfficeFilter = idOffices.length;
        this.getClients(idOffices);
      }
      if (idOffices.length == 0) {
        this.lengthOfficeFilter = 0;
        this.clientFilter = [];
        this.clients = [];
      }

      if (clients.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => clients.indexOf(item.corporate.id) != -1
        );
      }

      if (clientTypes.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => clientTypes.indexOf(item.insurance_type) != -1
        );
      }

      if (status.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => status.indexOf(item.status) != -1
        );
      }

      if (coverages.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => coverages.indexOf(item.coverage.id) != -1
        );
      }

      if (carriers.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => carriers.indexOf(item.carrier.id) != -1
        );
      }

      if (this.dateFilter[0] != null && this.dateFilter[1] != null) {
        this.tableData = this.tableData.filter(item => {
          var check = new Date(item.expiration_date);
          if (check > this.dateFilter[0] && check < this.dateFilter[1]) {
            return true;
          } else {
            return false;
          }
        });
      }
    },
    print() {
      let offices = JSON.stringify(this.officeFilter);

      let clients = JSON.stringify(this.clientFilter);

      let clientTypes = JSON.stringify(this.clientTypeFilter.map((user) => {
        return user.name.toLowerCase();
      }));

      let status = JSON.stringify(this.statusFilter.map((status) => {
        return status.status;
      }));

      let coverages = JSON.stringify(this.coverageFilter);

      let carriers = JSON.stringify(this.carrierFilter);

      let date = JSON.stringify(this.dateFilter)

      let routeData = this.$router.resolve({
        name: "insurancePrint",
        query: {
          offices: offices,
          clients: clients,
          clientTypes: clientTypes,
          status: status,
          coverages: coverages,
          carriers: carriers,
          date: date,
        },
      });
      window.open(routeData.href, "_blank");
    },
    handleClose(done) {
      this.$confirm("Are you sure you want to close this?")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },
    remove(row, index) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#67C23A",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          policy
            .delete(row)
            .then((response) => {
              this.tableData.splice(index, 1);
              this.data.splice(index, 1);
            })
            .catch(() => {
              this.$message({
                message: "Opps... Something wrong",
                type: "error",
                customClass: "message-error",
              });
            });
        }
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
th,
td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.mx-datepicker {
  width: 100% !important;
}

.mx-input {
  height: 42px !important;
}
</style>
